import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Container from './container';
import Link from './link';

export default function Hero() {
  return (
    <Container>
      <div className="flex flex-col items-center justify-center lg:flex-row">
        <div className="w-full max-w-lg lg:order-1 lg:w-[60%] lg:max-w-none">
          <StaticImage
            className="mt-8 md:mt-12"
            src="../images/photo.png"
            aria-hidden
            placeholder="none"
            layout="fullWidth"
            quality="95"
            alt=""
          />
        </div>
        <div className="lg:order-0 flex max-w-lg flex-col xs:items-start lg:w-[40%] lg:items-start lg:text-left ">
          <h1 className="max-w-lg text-4xl font-bold text-slate-200 xxs:text-5xl xs:text-6xl lg:-mr-32 lg:max-w-none xl:-mr-44 xl:text-7xl">
            Software Engineer & 
            <span className="inline bg-gradient-to-br from-cyan-400 via-sky-400 to-violet-500 bg-clip-text text-transparent">
              UI Designer
            </span>
          </h1>
          <p className="mt-4 max-w-lg text-lg leading-snug text-slate-500 xxs:mt-8 xxs:text-xl xs:text-2xl">
            <span className="text-slate-200">Hey, my name is Ivan Volti.</span> I’m a software
            engineer with over 10 years of experience and a full-stack background. I help companies
            design & build simple, beautiful, and memorable digital products.
          </p>
          <p className="mt-4 max-w-lg text-lg leading-snug text-slate-500 xxs:text-xl xs:text-2xl">
            Currently, I focus on front-end development and UI design at Precision Nutrition.
          </p>

          <Link
            className="mt-12 flex h-12 transform items-center justify-center whitespace-nowrap rounded-lg bg-gradient-to-r from-cyan-500 via-sky-500 to-indigo-400 p-[2px] px-12 py-3 text-lg font-semibold leading-none text-black hover:scale-[1.03] lg:mt-14 lg:text-xl lg:leading-none"
            to="mailto:hello@volti.dev"
          >
            Get in touch
          </Link>
        </div>
      </div>
    </Container>
  );
}
